<template>
    <div class="container">    
    <b-row>
        <b-col class="mx-1">
            <b-row
            class="content-header"
            >
            <!-- Content Left -->
            <b-col
                class="content-header-left my-2"
                >
                <b-row>
                    <b-col cols="12">
                    <h2 class="content-header-title float-left pr-1 mb-0">
                        Όροι χρήσης
                    </h2>
                    </b-col>
                </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-card>
                        <b-card-text class="p-2">
  <p>Με την αποδοχή των όρων της παρούσας, συνάπτεται σύμβαση παροχής υπηρεσιών, μεταξύ:</p>
  <p>αφενός</p>
  <p>της Ιδιωτικής Κεφαλαιουχικής Εταιρείας με την επωνυμία «<strong>LINKWISE IKE</strong>», που εδρεύει στη Νέα Ιωνία Αττικής, επί της οδού Αβέρωφ, αριθμός 24, με ΑΦΜ 800531556, Δ.Ο.Υ. Νέας Ιωνίας, καλούμενη εφεξής «<strong>Εταιρεία</strong>»,</p>
  <p>και αφετέρου</p>
  <p>του αντισυμβαλλόμενου, ο οποίος επιθυμεί να διαθέσει τα προϊόντα του προς πώληση μέσω του Restock και εγγράφεται με την ιδιότητα αυτή στην Ιστοσελίδα, καλούμενου εφεξής «<strong>Προμηθευτής</strong>»,</p>
  <p>και από κοινού καλούμενοι ως «τα Μέρη» ή «οι Συμβαλλόμενοι»,</p>
  <p><strong>οι οποίοι συμφωνούν και κάνουν αμοιβαίως αποδεκτά τα ακόλουθα:</strong></p>
  <h4>ΠΡΟΟΙΜΙΟ</h4>
  <p>-       Η Εταιρεία, στο πλαίσιο των δραστηριοτήτων της, διατηρεί και διαχειρίζεται την ιστοσελίδα με το όνομα χώρου <a href="http://www.restock.gr">restock.gr</a>, και όλα τα subdomains αυτού, στην οποία λειτουργεί marketplace χονδρικών αγορών (εφεξής το “<strong>RESTOCK</strong>” ή η «<strong>Ιστοσελίδα</strong>»), μέσω του οποίου οι συνεργαζόμενοι χονδρέμποροι εκθέτουν τα προϊόντα τους και οι επισκέπτες – επαγγελματίες έχουν τη δυνατότητα online αγοράς των προϊόντων αυτών.</p>
  <p>-       Ο Προμηθευτής διατηρεί εμπορική επιχείρηση και επιθυμεί να ενταχθεί στο RESTOCK ως χονδρέμπορος και να παρέχει μέσω αυτού τα προϊόντα του προς πώληση, σύμφωνα με τους όρους της παρούσας.</p>
  <h4>ΟΡΙΣΜΟΙ</h4>
  <p>Οι ακόλουθοι όροι, στο πλαίσιο της παρούσας σύμβασης, θα έχουν την εξής έννοια:</p>
  <p><strong>«Πελάτης»</strong> είναι κάθε φυσικό ή νομικό πρόσωπο που διατηρεί επιχείρηση στην Ελλάδα, και επισκέπτεται την Ιστοσελίδα με σκοπό την πραγματοποίηση παραγγελιών μέσω αυτής.</p>
  <p><strong>«Προϊόντα»</strong> είναι τα κάθε είδους αγαθά που διατίθενται προς πώληση από τον Προμηθευτή μέσω της Ιστοσελίδας.</p>
  <p><strong>«Καλάθι Αγορών»</strong> είναι το σύνολο των επιλεγμένων Προϊόντων του Πελάτη προς αγορά.</p>
  <h4>Άρθρο 1 - Αντικείμενο της σύμβασης</h4>
  <p>1.     Ο Προμηθευτής θα διαθέτει προς πώληση μέσω της Ιστοσελίδας το σύνολο ή τμήμα των προϊόντων που εμπορεύεται η επιχείρησή του. Η Εταιρεία διατηρεί το δικαίωμα να αρνηθεί την καταχώρηση των Προϊόντων που δικαιολογημένα κρίνει ότι δεν συνάδουν με τον χαρακτήρα της Ιστοσελίδας.</p>
  <p>2.     Η Εταιρεία θα καταχωρεί τα προϊόντα του Προμηθευτή στην Ιστοσελίδα και θα τα ταξινομεί με βάση τα χαρακτηριστικά τους, με σκοπό την παροχή της δυνατότητας εύκολης εύρεσης από τον Πελάτη με τη χρήση των αντίστοιχων φίλτρων. Κάθε προϊόν θα εμφανίζεται με σύντομη περιγραφή και θα ακολουθούν όλοι οι προμηθευτές που το διαθέτουν μέσω της Ιστοσελίδας.</p>
  <h4>Άρθρο 2. Οικονομικοί όροι</h4>
  <p>1.     Η αμοιβή της Εταιρείας για τις παρεχόμενες βάσει της παρούσας υπηρεσίες βασίζεται στο μοντέλο cost per sale (χρέωση ανά πώληση), ήτοι ο Προμηθευτής θα καταβάλλει στην Εταιρεία μόνον προμήθεια επί των πωλήσεων που θα πραγματοποιεί μέσω της Ιστοσελίδας.</p>
  <p>2.     Η ως άνω προμήθεια θα υπολογίζεται επί της τιμής πώλησης των προϊόντων μη συμπεριλαμβανομένου ΦΠΑ, χωρίς την προσθήκη τυχόν μεταφορικών ή άλλων εξόδων που βαρύνουν την παραγγελία.</p>
  <p>3.     Το ποσοστό της προμήθειας ορίζεται στον πίνακα προμηθειών που βρίσκεται αναρτημένος στην Πλατφόρμα Ενημέρωσης Προμηθευτών.</p>
  <p>4.     Η χρέωση θα γίνεται μηνιαίως και δη εντός του πρώτου δεκαημέρου εκάστου μηνός για τις παραγγελίες που εκτελέστηκαν επιτυχώς εντός του αμέσως προηγούμενου μηνός, οπότε και η Εταιρεία θα εκδίδει το αντίστοιχο τιμολόγιο παροχής υπηρεσιών και θα το αποστέλλει ηλεκτρονικά αυθημερόν στον Προμηθευτή.</p>
  <p>Η εξόφληση κάθε τιμολογίου από τον Προμηθευτή θα γίνεται ως εξής:</p>
  <p>-       Δια συμψηφισμού για τις προμήθειες που έχει ήδη παρακρατήσει η Εταιρεία από τις εισπράξεις μέσω πιστωτικής κάρτας ή IRIS,</p>
  <p>-       Δια καταθέσεως σε έναν εκ των τραπεζικών λογαριασμών που θα υποδείξει η Εταιρεία, εντός τριάντα (30) ημερών από την έκδοση του τιμολογίου, για το υπόλοιπο ποσό εκάστου τιμολογίου που θα αφορά στις παραγγελίες που έχουν πληρωθεί με αντικαταβολή ή με πίστωση.</p>
  <p>5.     Η Εταιρεία διατηρεί το δικαίωμα να μεταφέρει το προς τιμολόγηση ποσό σε τιμολόγιο επόμενου μήνα εφόσον αυτό δεν υπερβαίνει το ποσό των τριάντα ευρώ (30€).</p>
  <h4>Άρθρο 3. Διάρκεια - Λύση</h4>
  <p>1.     Η διάρκεια της παρούσας σύμβασης συμφωνείται αορίστου χρόνου, αρχόμενη από την ημερομηνία υπογραφής της.</p>
  <p>2.     Κάθε συμβαλλόμενο μέρος δικαιούται να καταγγείλει οποτεδήποτε αζημίως την παρούσα κατόπιν έγγραφης προειδοποίησης του έτερου μέρους.</p>
  <p>3.     Μετά την καθ’ οιονδήποτε τρόπο λύση  της παρούσας σύμβασης, τα Μέρη θα προβούν σε εκκαθάριση των εκατέρωθεν απαιτήσεων εντός δέκα (10) εργασίμων ημερών.</p>
  <h4>Άρθρο 4 - Υποχρεώσεις του Προμηθευτή</h4>
  <p>1.     Ο Προμηθευτής υποχρεούται να γνωστοποιήσει στην Εταιρεία τα πλήρη και αληθή στοιχεία της επιχείρησής του, ήτοι επωνυμία, διακριτικό τίτλο, διεύθυνση, ΑΦΜ, Δ.Ο.Υ., αριθμό ΓΕΜΗ, αριθμό τηλεφώνου και διεύθυνση email, τα οποία θα προβάλλονται μέσω της Ιστοσελίδας, με σκοπό την ενημέρωση των Πελατών. Τα στοιχεία αυτά επικαιροποιούνται όποτε υπάρξει τροποποίησή τους, με ευθύνη του Προμηθευτή.</p>
  <p>2.     Ο Προμηθευτής υποχρεούται να διαθέτει μέσω της Ιστοσελίδας αποκλειστικά προϊόντα που ανταποκρίνονται σε υψηλά πρότυπα ποιότητας και ασφάλειας. Σε κάθε περίπτωση, ο Προμηθευτής υποχρεούται να απέχει από κάθε πράξη ή παράλειψη που μπορεί να οδηγήσει σε παραπλάνηση των υποψηφίων πελατών, όπως ενδεικτικά η παροχή ψευδούς ή ανακριβούς περιγραφής των χαρακτηριστικών της προέλευσης του προϊόντος, η παροχή ψευδών ή ανακριβών πληροφοριών σε σχέση με την κατάσταση, τη διαθεσιμότητα ή την τιμή του προϊόντος, κ.α.</p>
  <p>3.     Ο Προμηθευτής υποχρεούται να απέχει από κάθε πράξη ή παράλειψη, από την οποία δύναται να προκύψει ζημία της Εταιρείας, όπως ενδεικτικά η επιβολή προστίμου ή άλλων κυρώσεων από τις αρμόδιες αρχές, η βλάβη στη φήμη της Εταιρείας ή/και της Ιστοσελίδας, κλπ.</p>
  <p>4.     Ο Προμηθευτής υποχρεούται να συμμορφώνεται με το σύνολο της κείμενης νομοθεσίας που διέπει τη δραστηριότητά του, τη νομοθεσία περί ηλεκτρονικού εμπορίου και εμπορικών σημάτων, τη νομοθεσία περί διαχείρισης αποβλήτων κ.ο.κ.</p>
  <p>5.     Σε περίπτωση παραβίασης των υποχρεώσεων του παρόντος άρθρου από τον Προμηθευτή, η Εταιρεία θα αποστέλλει έγγραφη ειδοποίηση μέσω ηλεκτρονικού ταχυδρομείου στον Προμηθευτή και εφόσον ο Προμηθευτής δεν προβεί άμεσα σε κατάλληλες ενέργειες για την άρση της παραβίασης, η Εταιρεία δύναται να προβεί σε άμεση διακοπή της παροχής των υπηρεσιών και αφαίρεση του καταστήματος του Προμηθευτή από την Ιστοσελίδα, άνευ ετέρου.</p>
  <h4>Άρθρο 5 - Υποχρεώσεις Εταιρείας</h4>
  <p>Η Εταιρεία υποχρεούται να διατηρεί σε πλήρη λειτουργία την Ιστοσελίδα και να μεριμνά για την επίλυση κάθε δυσλειτουργίας αυτής εντός εύλογου χρονικού διαστήματος.</p>
  <h4>Άρθρο 6 - Πνευματικά Δικαιώματα</h4>
  <p>Ο Προμηθευτής παρέχει στην Εταιρεία μη αποκλειστική, αορίστου διάρκειας και απαλλαγμένη από καταβολή δικαιωμάτων άδεια χρήσης και εκμετάλλευσης των πληροφοριών και των φωτογραφιών που ο ίδιος προωθεί στην Εταιρεία, με σκοπό την προβολή των Προϊόντων του μέσω της Ιστοσελίδας. Λόγω της φύσης της λειτουργίας της Πλατφόρμας, η Εταιρεία θα επιλέγει φωτογραφίες των προϊόντων, οι οποίες θα προβάλλονται κοινά για όλους τους προμηθευτές του ίδιου προϊόντος. Ρητά συμφωνείται πως ο Προμηθευτής παραχωρεί την άδεια για την προβολή των φωτογραφιών με τους ως άνω όρους για αόριστη διάρκεια, ακόμη και σε περίπτωση που ο ίδιος παύσει να διαθέτει προς πώληση το συγκεκριμένο προϊόν ή και αν παύσει τη συνεργασία του με την Πλατφόρμα.</p>
  <h4>Άρθρο 7 - Εμπιστευτικότητα</h4>
  <p>Τα Μέρη δεσμεύονται να τηρούν απόλυτη εχεμύθεια σε σχέση με όλες τις εμπιστευτικές πληροφορίες του έτερου μέρους οι οποίες τους γνωστοποιούνται στο πλαίσιο της εκτέλεσης της παρούσας σύμβασης. Ως εμπιστευτικές πληροφορίες νοούνται όλα τα στοιχεία που αφορούν την επιχειρησιακή οργάνωση,  εμπορικά μυστικά, τεχνογνωσία, τιμολογιακή πολιτική, ποσοστά προμήθειας, επιχειρηματικά σχέδια, επιχειρηματική οργάνωση και κάθε είδους οικονομική και λοιπή δραστηριότητα εκάστου συμβαλλομένου μέρους.</p>
  <h4>Άρθρο 8 - Προσωπικά Δεδομένα</h4>
  <p>Κατά την εκτέλεση της παρούσας σύμβασης τα συμβαλλόμενα μέρη θα προβούν σε επεξεργασία δεδομένων προσωπικού χαρακτήρα των πελατών, καθώς και των υπαλλήλων και εκπροσώπων του Προμηθευτή που θα αποκτήσουν πρόσβαση στο διαχειριστικό περιβάλλον του Restock.</p>
  <p>Οι ειδικότεροι όροι αυτής επεξεργασίας περιγράφονται στη Σύμβαση Επεξεργασίας Δεδομένων, που προσαρτάται στην παρούσα ως Παράρτημα Ι.</p>
  <h4>Άρθρο 9 - Διαδικασία εγγραφής Προμηθευτή</h4>
  <p>Για να εγγραφεί κάποιος στην Ιστοσελίδα ως Προμηθευτής, θα πρέπει αρχικά να δημιουργήσει έναν λογαριασμό Πελάτη, τον οποίο οι διαχειριστές του Restock θα αναβαθμίσουν σε λογαριασμό Προμηθευτή.</p>
  <p>Επιπλέον, για την ολοκλήρωση της διαδικασίας εγγραφής και την εμφάνιση του Προμηθευτή και των Προϊόντων του στην Ιστοσελίδα, ο Προμηθευτής θα πρέπει να αποστείλει στην Εταιρεία τα παρακάτω απαιτούμενα στοιχεία και αρχεία:</p>
  <p>-       Μια σύντομη περιγραφή της επιχείρησης</p>
  <p>-       Το λογότυπο της επιχείρησης</p>
  <p>-       Φωτογραφίες της επιχείρησης ή/και διαφημιστικές εικόνες που θα τοποθετηθούν στο επαγγελματικό προφίλ της επιχείρησης</p>
  <p>-       Περιοχές εξυπηρέτησης</p>
  <p>-       Το ελάχιστο κόστος παραγγελίας, γενικά ή ανά περιοχή</p>
  <p>-       Τα ενδεχόμενα κόστη μεταφορικών</p>
  <p>-       Τους χρόνους παράδοσης</p>
  <p>Σημειώνεται ότι τα παραπάνω στοιχεία θα είναι δημοσίως διαθέσιμα στο Restock.gr, ώστε να εξυπηρετείται η άμεση ενημέρωση και η ευκολία των αγοραστών.</p>
  <p>Η Εταιρεία δεν φέρει ευθύνη για τυχόν ανακρίβειες ή παραλείψεις στις πληροφορίες που παρέχονται από τους Προμηθευτές.</p>
  <h4>Άρθρο 10 - Διαδικασία καταχώρησης Προϊόντων</h4>
  <p>Ο Προμηθευτής αποστέλλει στην Εταιρεία με ηλεκτρονικό αρχείο τα προϊόντα που επιθυμεί να προβάλλει μέσω της Ιστοσελίδας, με ακριβή και ορθή περιγραφή των Προϊόντων, των προδιαγραφών τους και των τιμών πώλησης.</p>
  <p>Η Εταιρεία προβαίνει σε επεξεργασία του αρχείου με σκοπό την ομαλοποίηση των δεδομένων, την ομαδοποίηση και ένταξή τους σε τυχόν υπάρχοντες κωδικούς στην πλατφόρμα και τη μετατροπή τίτλων, περιγραφών, φωτογραφιών κλπ. ώστε να ταιριάζουν με το ύφος και τις προδιαγραφές της Πλατφόρμας.</p>
  <p>Η Εταιρεία δεν φέρει καμία ευθύνη για τυχόν σφάλματα ή ελλείψεις στην περιγραφή των προϊόντων.</p>
  <h4>Άρθρο 11 - Διαδικασία πώλησης</h4>
  <p>-       Η διαδικασία της πώλησης γίνεται ως εξής :</p>
  <p>Ο Πελάτης επιλέγει τα προϊόντα που επιθυμεί, τα τοποθετεί στο Καλάθι Αγορών του και επιλέγει τρόπο πληρωμής ανάλογα με τις δυνατότητες που παρέχει κάθε προμηθευτής.</p>
  <p>Με την ολοκλήρωση της παραγγελίας από τον Πελάτη, η Εταιρεία προωθεί την παραγγελία στον Προμηθευτή, γνωστοποιώντας του τα εξής στοιχεία: α) κωδικό παραγγελίας, β) στοιχεία πωλούμενων προϊόντων (κωδικός είδους, τίτλος είδους, φωτογραφία, τιμή μονάδας και ποσότητες παραγγελθέντων προϊόντων), γ) στοιχεία Χρήστη (επωνυμία επιχείρησης, Α.Φ.Μ., Δ.Ο.Υ., διεύθυνση, επάγγελμα, e-mail), δ) τυχόν ειδικές σημειώσεις του Πελάτη.</p>
  <p>Εφόσον ο Πελάτης έχει επιλέξει ως τρόπο πληρωμής την Αντικαταβολή, ο Προμηθευτής προχωρά στην εκτέλεση της παραγγελίας και την είσπραξη της αξίας της απ’ ευθείας από τον Πελάτη κατά την παράδοση της παραγγελίας.</p>
  <p>Εάν ο Πελάτης έχει επιλέξει πληρωμή μέσω πιστωτικής κάρτας ή IRIS, τότε η Εταιρεία, πριν την προώθηση της παραγγελίας προς τον Προμηθευτή, εισπράττει το τίμημα της παραγγελίας από τον Πελάτη και αποδίδει το τίμημα στον Προμηθευτή, μετ’αφαίρεσιν της συμφωνηθείσας προμήθειας, συγχρόνως με την προώθηση της παραγγελίας. Δια του παρόντος, ο Προμηθευτής εξουσιοδοτεί την Εταιρεία να πραγματοποιεί για λογαριασμό του τις ως άνω εισπράξεις μέσω του νομίμως λειτουργούντος τραπεζικού ιδρύματος EveryPay και με βάση τους όρους λειτουργίας του όπως δημοσιεύονται στην ιστοσελίδα www.everypay.gr.</p>
  <p>Σε κάθε περίπτωση, με την εκτέλεση της Παραγγελίας, ο Προμηθευτής υποχρεούται στην έκδοση του νομίμου φορολογικού παραστατικού πώλησης (Τιμολογίου Πώλησης), το οποίο είτε θα παραδίδει στον Πελάτη σε έγχαρτη μορφή κατά την παράδοση των προϊόντων, είτε θα το αποστέλλει στον Πελάτη μέσω ηλεκτρονικού ταχυδρομείου στην ηλεκτρονική διεύθυνση που θα έχει δηλωθεί στην Ιστοσελίδα.</p>
  <p>Σε περίπτωση που στο μέλλον η Ιστοσελίδα παράσχει τη δυνατότητα πληρωμής με πρόσθετα μέσα πληρωμής, θα ενημερώνει εγγράφως τον Προμηθευτή, γνωστοποιώντας του και τους τυχόν ειδικούς όρους και προϋποθέσεις χρήσης του μέσου πληρωμής.</p>
  <p>-       Η Εταιρεία, συγχρόνως με την προώθηση της παραγγελίας στον Προμηθευτή, καταχωρεί στο αρχείο καταγραφής των παραγγελιών τα στοιχεία της παραγγελίας, ήτοι τον αριθμό παραγγελίας, την ημερομηνία και ώρα της πώλησης, το είδος, τις ποσότητες και τις τιμές των πωληθέντων προϊόντων.</p>
  <p>-       Η Εταιρεία θα παρέχει τη δυνατότητα στον Πελάτη να επικοινωνεί με εκπροσώπους της μέσω email ή μέσω τηλεφώνου για την επίλυση τυχόν προβλημάτων ή την εν γένει διευκόλυνση της διαδικασίας ηλεκτρονικής αγοράς μέσω της Ιστοσελίδας. Η παροχή της υπηρεσίας αυτής, ουδόλως επηρεάζει τη σχέση των μερών ως ανεξαρτήτων μερών, ούτε καθιστά την Εταιρεία συμβαλλόμενο μέρος στη σύμβαση πωλήσεως που καταρτίζεται μεταξύ του Προμηθευτή και του Πελάτη.</p>
  <p>-       Η Εταιρεία δύναται να προτείνει στον Πελάτη παρεμφερή ή παρεπόμενα προϊόντα, ώστε να προωθεί τις συνδυαστικές πωλήσεις. Τα προωθούμενα αυτά προϊόντα δύνανται να παρέχονται από τον ίδιο προμηθευτή ή έτερους προμηθευτές που συμμετέχουν στην Ιστοσελίδα, η δε επιλογή θα γίνεται με κριτήρια τα οποία θα ορίζει κατά την απόλυτη κρίση της η Εταιρεία.</p>
  <p>-       Ο Προμηθευτής είναι αποκλειστικά υπεύθυνος για τη συλλογή, συσκευασία και αποστολή κάθε παραγγελίας στον Πελάτη. Κατά συνέπεια, η Εταιρεία ουδεμία ευθύνη φέρει για τυχόν ελαττωματικά προϊόντα, ελλείψεις των παραδοθέντων προϊόντων, σφάλματα κατά την παράδοση των προϊόντων ή/και απώλεια ή καταστροφή των προϊόντων πριν την παράδοση στον Πελάτη για τα οποία μόνος υπεύθυνος είναι ο Προμηθευτής.</p>
  <p>Σε περίπτωση που ο Πελάτης ή οιοσδήποτε τρίτος στραφεί κατά της Εταιρείας για οποιαδήποτε αιτία οφειλόμενη σε υπαιτιότητα του Προμηθευτή, τότε ο Προμηθευτής υποχρεούται να απαλλάξει την Εταιρεία από κάθε ευθύνη και να της καταβάλει κάθε ποσό που τυχόν αυτή υποχρεωθεί να καταβάλει σε τρίτους, καθώς και να την αποζημιώσει για κάθε περαιτέρω θετική ή αποθετική ζημία της που σχετίζεται άμεσα ή έμμεσα με τα προϊόντα ή/και τη μη προσήκουσα εκτέλεση των παραγγελιών από τον Προμηθευτή.</p>
  
  <h4>Άρθρο 12 - Πλατφόρμα Ενημέρωσης Προμηθευτών</h4>
  <p>-       Η Ιστοσελίδα παρέχει στον Προμηθευτή τη δυνατότητα διαρκούς online ενημέρωσης σχετικά με τις παραγγελίες που λαμβάνει μέσω του Restock, δια του ηλεκτρονικού συστήματος με τίτλο «<a>Restock</a> Supplier Interface».</p>
  <p>-       Στο Restock Supplier Interface, ο Προμηθευτής έχει διαρκή πρόσβαση στο σύστημα παραγγελιών, όπου μπορεί να ενημερώνεται για τα αναλυτικά στοιχεία των παραγγελιών που λαμβάνει μέσω του  Restock και για το σύνολο των συναλλαγών του με την Εταιρεία, να επεξεργάζεται την κατάσταση (status) των παραγγελιών του, να τροποποιεί τις τιμές των προϊόντων του και να απενεργοποιεί προϊόντα, καθώς και να μεταβάλλει τις περιοχές παράδοσης και να προσθέτει προσφορές ή νέους τιμοκαταλόγους στην Πλατφόρμα.</p>
  <h4>Άρθρο 13 - Σχέση Μερών</h4>
  <p>1.     Ο Προμηθευτής διατηρεί ανεξάρτητη επιχείρηση, συναλλάσσεται με τους τρίτους με το δικό του όνομα και για δικό του λογαριασμό και κατά συνέπεια η Εταιρεία δεν αναλαμβάνει υποχρεώσεις του Προμηθευτή έναντι οποιουδήποτε τρίτου, όπως ενδεικτικά και όχι αποκλειστικά των πελατών, των αρχών, των συνεργατών, των υπαλλήλων ή των προστηθέντων του, έστω και αν οι υποχρεώσεις αυτές σχετίζονται άμεσα ή έμμεσα με συναλλαγές μέσω της Ιστοσελίδας.</p>
  <p>2.      Ο Προμηθευτής δεν έχει το δικαίωμα να ενεργεί πράξεις στο όνομα της Εταιρείας και γενικά να την δεσμεύει έναντι τρίτων με οποιονδήποτε τρόπο και για οποιονδήποτε λόγο.</p>
  <p>3.      Συμφωνείται και δηλώνεται ρητά ότι με την παρούσα σύμβαση παροχής ανεξαρτήτων υπηρεσιών, ουδεμία εταιρική σχέση, σχέση αντιπροσώπευσης, ή κοινοπραξία δημιουργείται μεταξύ των συμβαλλομένων.</p>
  <h4>Άρθρο 14. - Παραίτηση</h4>
  <p>Οποιαδήποτε καθυστέρηση, ή αμέλεια, ή παράλειψη, ή ανοχή του ενός συμβαλλόμενου στην επιβολή της τήρησης κάποιου όρου της σύμβασης από τον άλλο ή στην άσκηση κάποιου δικαιώματός του, προβλεπόμενου στην παρούσα, δεν αποτελεί παραίτηση, ούτε το αναιρεί, αλλά τα μέρη έχουν τη δυνατότητα να το ασκούν οποτεδήποτε κατά την εξέλιξη της παρούσας σύμβασης.</p>
  <h4>Άρθρο 15 – Εκχώρηση</h4>
  <p>Κανένα από τα συμβαλλόμενα μέρη δεν έχει δικαίωμα να εκχωρήσει τις απαιτήσεις του από τη σύμβαση αυτή ή οποιαδήποτε από τα δικαιώματα ή τις υποχρεώσεις που περιέχονται σε αυτήν ή απορρέουν από αυτή σε οποιοδήποτε τρίτο, χωρίς την προηγούμενη έγγραφη συγκατάθεση του άλλου μέρους.</p>
  <h4>Άρθρο 16 – Τροποποίηση</h4>
  <p>Συμφωνείται ότι οποιαδήποτε τροποποίηση των παρόντων όρων από την Εταιρεία, θα γνωστοποιείται στον Προμηθευτή με κάθε πρόσφορο τρόπο (ενδεικτικά μέσω ανακοίνωσης στην Πλατφόρμα Ενημέρωσης Προμηθευτών «Restock Supplier Interface») και θα ισχύει είκοσι (20) ημέρες μετά τη γνωστοποίηση.</p>
  <h4>Άρθρο 17 – Ακύρωση διάταξης</h4>
  <p>Τυχόν ακυρότητα οποιασδήποτε διάταξης της παρούσας, δεν επηρεάζει το κύρος των λοιπών όρων της, και τα συμβαλλόμενα μέρη συμφωνούν να αντικαθιστούν κάθε άκυρη διάταξη με άλλη έγκυρη, που να προσεγγίζει περισσότερο τον αντικειμενικό σκοπό της άκυρης.</p>
  <h4>Άρθρο 18 - Επίλυση Διαφορών</h4>
  <p>Η παρούσα Σύμβαση διέπεται από το Ελληνικό Δίκαιο. Για την επίλυση οποιασδήποτε διαφωνίας ή διαφοράς που τυχόν ανακύψει αναφορικά με την ερμηνεία ή και την εφαρμογή των όρων του παρόντος Συμφωνητικού, συμφωνείται ότι αποκλειστικά αρμόδια είναι τα δικαστήρια της Αθήνας, που εφαρμόζουν το Ελληνικό Δίκαιο.</p>
  <h4>Παράρτημα Ι</h4>
  <h4>Σύμβαση Επεξεργασίας Δεδομένων</h4>
  <h4>1. ΟΡΙΣΜΟΙ</h4>
  <p>«<strong>Γενικός Κανονισμός για την Προστασία Δεδομένων</strong>» ή «ΓΚΠΔ» νοείται ο Κανονισμός (ΕΕ) 2016/679.</p>
  <p>Οι όροι «<strong>Δεδομένα Προσωπικού Χαρακτήρα</strong> <strong>/ Προσωπικά Δεδομένα</strong>», <strong>«Υπεύθυνος Επεξεργασίας»</strong>, «<strong>Εκτελών την Επεξεργασία</strong>», «<strong>Υποκείμενο Δεδομένων</strong><strong>», «Επεξεργασία»,</strong>  έχουν την έννοια που τους αποδίδεται από τον  Γενικό Κανονισμό για την Προστασία Δεδομένων 679/2016 (ΓΚΠΔ).</p>
  <p>Ως <strong>«Υπεύθυνος Επεξεργασίας» ή «Εταιρεία»</strong> νοείται η εταιρεία με την επωνυμία “LINKWISE IKE” και το διακριτικό τίτλο «LINKWISE», που εδρεύει στη διεύθυνση Αβέρωφ 24, Νέα Ιωνία, 142 32, με ΑΦΜ 800531556, ΔΟΥ ΑΓ. ΑΝΑΡΓΥΡΩΝ και Αρ. ΓΕΜΗ 127851601000, με e-mail <a href="mailto:contact@restock.gr">contact@restock.gr</a> .</p>
  <p>Ως <strong>«Εκτελών την επεξεργασία»</strong> νοείται ο Προμηθευτής.</p>
  <p>Ως «<strong>Ιστοσελίδα</strong>» ή «<strong>Restock</strong>» ή «<strong>Πλατφόρμα</strong>» νοείται η ιστοσελίδα που διατηρεί η Εταιρεία στη διαδικτυακή διεύθυνση με όνομα χώρου www.restock.gr, και όλα τα subdomains αυτού shop.restock.gr .</p>
  <h4>2. ΕΙΣΑΓΩΓΗ</h4>
  <p>Στο πλαίσιο της συνεργασίας των μερών, και δη της ένταξης της εμπορικής επιχείρησης Προμηθευτή στην Πλατφόρμα, η Εταιρεία, ενεργούσα ως <i>Υπεύθυνος Επεξεργασίας</i>, θα διαβιβάζει στον Προμηθευτή, που θα ενεργεί ως <i>Εκτελών την Επεξεργασία</i>, τα προσωπικά δεδομένα των χρηστών της Πλατφόρμας, στο βαθμό που αυτό απαιτείται για την εύρυθμη λειτουργία της.</strong></p>
  <p>Η παρούσα σύμβαση επεξεργασίας δεδομένων ορίζει τα δικαιώματα και τις υποχρεώσεις του Υπευθύνου Επεξεργασίας και του Εκτελούντα την Επεξεργασία, στο πλαίσιο της επεξεργασίας δεδομένων προσωπικού κατά τα ανωτέρω.</p>
  <h4>3. Πεδίο εφαρμογής</h4>
  <p><strong>3</strong><strong>.</strong><strong>1</strong><strong>.</strong> O Υπεύθυνος Επεξεργασίας, στο πλαίσιο της λειτουργίας της Πλατφόρμας, συλλέγει και επεξεργάζεται στο βαθμό που αυτό κρίνεται απαραίτητο, τα προσωπικά δεδομένα των υποκειμένων των δεδομένων (“<strong>Υποκείμενα των Δεδομένων</strong>”), ήτοι των χρηστών της Ιστοσελίδας.</p>
  <p><strong>3.</strong><strong>2.</strong> Τα προσωπικά δεδομένα που επεξεργάζεται ο Εκτελών την Επεξεργασία στο πλαίσιο της παροχής υπηρεσιών βάσει της Αρχικής Σύμβασης είναι τα εξής:</p>
  <table>
    <tr>
      <td>
        <p><strong>Είδος προσωπικών δεδομένων</strong></p>
      </td>
      <td>
        <p>1. Ονοματεπώνυμο/ επωνυμία επιχείρησης</p>
        <p>2. Ταχυδρομική Διεύθυνση</p>
        <p>3. Αριθμός τηλεφώνου (σταθερό ή/και κινητό)</p>
        <p>4. E-mail</p>
        <p>5. Ονοματεπώνυμο υπευθύνου</p>
        <p>6. Περιεχόμενο τυχόν σχολίων του Χρήστη για την παραγγελία</p>
        <p>7. Οικονομικά στοιχεία παραγγελίας</p>
      </td>
    </tr>
    <tr>
      <td>
        <p><strong>Κατηγορίες φυσικών προσώπων</strong></p>
      </td>
      <td>
        <p>Χρήστες/ Επισκέπτες της Ιστοσελίδας που ολοκληρώνουν παραγγελίες από τον Εκτελούντα την επεξεργασία Προμηθευτή.</p>
      </td>
    </tr>
    <tr>
      <td>
        <p><strong>Διάρκεια επεξεργασίας</strong></p>
      </td>
      <td>
        <p>Όμοια με τη διάρκεια της σύμβασης.</p>
        <p>Ο Εκτελών την επεξεργασία μετά την σύναψη της πώλησης καθίσταται Υπεύθυνος επεξεργασίας για τα δεδομένα των πελατών του και τα επεξεργάζεται σύμφωνα με το Νόμο, μόνον στο βαθμό που απαιτείται για τη διαχείριση της συναλλαγής.</p>
      </td>
    </tr>
    <tr>
      <td>
        <p><strong>Σκοποί επεξεργασίας</strong></p>
      </td>
      <td>
        <p>Διεκπεραίωση των παραγγελιών που πραγματοποιούνται μέσω της Ιστοσελίδας, παράδοση των εμπορευμάτων και εν γένει εξυπηρέτηση των πελατών.</p>
        <p>Διευκρινίζεται ότι δεν επιτρέπεται η χρήση των προσωπικών δεδομένων για σκοπούς προώθησης προϊόντων ή οιασδήποτε ενέργειας marketing, καθώς η Εταιρεία δεν ζητεί και δεν λαμβάνει τέτοια συγκατάθεση από τα Υποκείμενα.</p>
      </td>
    </tr>
  </table>
  <p> </p>
  <h4> 4. Υποχρεώσεις του Εκτελούντα την Επεξεργασία</h4>
  <p><strong>4</strong><strong>.1.</strong> Ο Εκτελών την Επεξεργασία υποχρεούται να προβαίνει σε επεξεργασία των δεδομένων προσωπικού χαρακτήρα <strong>αποκλειστικά στο πλαίσιο της Αρχικής Σύμβασης και στον βαθμό που απαιτείται για την υλοποίηση των σκοπών αυτής</strong>.</p>
  <p><strong>4.2.</strong> Ο Εκτελών την Επεξεργασία επιβεβαιώνει ότι γνωρίζει τις ισχύουσες νομικές διατάξεις σχετικά με την προστασία των δεδομένων προσωπικού χαρακτήρα καθώς και τις αρχές σύννομης επεξεργασίας των δεδομένων προσωπικού χαρακτήρα.</p>
  <p><strong>4.3.</strong> Ο Εκτελών την Επεξεργασία είναι υποχρεωμένος να τηρεί αυστηρή εμπιστευτικότητα κατά την επεξεργασία των δεδομένων προσωπικού χαρακτήρα στο πλαίσιο της Αρχικής Σύμβασης.</p>
  <p><strong>4.4.</strong> Ο Εκτελών την Επεξεργασία επεξεργάζεται τα δεδομένα προσωπικού χαρακτήρα μόνο βάσει των εντολών/οδηγιών του Υπευθύνου Επεξεργασίας και ενημερώνει αμέσως τον Υπεύθυνο Επεξεργασίας, εάν, κατά την άποψή του, κάποια εντολή παραβιάζει τον ΓΚΠΔ ή άλλες ενωσιακές ή εθνικές διατάξεις περί προστασίας δεδομένων. Ο Εκτελών την Επεξεργασία οφείλει να παρέχει έγγραφες αποδείξεις εφαρμογής των οδηγιών που εκδόθηκαν από τον Υπεύθυνο Επεξεργασίας.</p>
  <p><strong>4.5.</strong> Οι προστηθέντες του Εκτελούντα την Επεξεργασία που θα μπορούσαν να έχουν πρόσβαση στα δεδομένα προσωπικού χαρακτήρα που υπόκεινται σε επεξεργασία για λογαριασμό του Υπευθύνου Επεξεργασίας, πρέπει να δεσμεύονται συμβατικά για την τήρηση της εμπιστευτικότητας των δεδομένων, με όρους τουλάχιστον εξίσου δεσμευτικούς με τους όρους της παρούσας σύμβασης. Ο Εκτελών την Επεξεργασία εξασφαλίζει ότι το προσωπικό και οι συνεργάτες του έχουν ενημερωθεί για τις σχετικές διατάξεις προστασίας δεδομένων και έχουν δεσμευτεί ως προς την τήρηση των διατάξεων της παρούσας σύμβασης, πριν αρχίσουν να επεξεργάζονται τα εν λόγω δεδομένα, καθώς και ότι η επεξεργασία των δεδομένων διεξάγεται υπό τη δέουσα εποπτεία του Εκτελούντα την Επεξεργασία. Τα αντίστοιχα μέτρα κατάρτισης και ευαισθητοποίησης προσωπικού κρίνεται αναγκαίο να επαναλαμβάνονται σε τακτική βάση.</p>
  <p><strong>4.</strong><strong>6</strong><strong>.</strong> Σε περίπτωση κατά την οποία ο Εκτελών την Επεξεργασία υπόκειται σε επιθεώρηση των εποπτικών αρχών ή οποιωνδήποτε άλλων φορέων ή σε περίπτωση όπου τα θιγόμενα πρόσωπα ασκούν οποιαδήποτε δικαιώματα κατά αυτού, τότε ο Εκτελών την Επεξεργασία υποχρεούται να ενημερώνει αμελλητί τον Υπεύθυνο Επεξεργασίας, εφόσον επηρεάζονται τα δεδομένα τα οποία επεξεργάζεται για λογαριασμό του Υπευθύνου Επεξεργασίας.</p>
  <p><strong>4.</strong><strong>7</strong> Οποιαδήποτε επεξεργασία δεδομένων προσωπικού χαρακτήρα στο πλαίσιο της παρούσας και της Αρχικής Σύμβασης, μπορεί να πραγματοποιηθεί <strong>μόνο</strong> <strong>εντός του Ευρωπαϊκού Οικονομικού Χώρου (</strong><strong>ΕΟΧ</strong><strong>)</strong>, ενώ απαγορεύεται ρητώς κάθε διαβίβαση ή προώθηση των προσωπικών δεδομένων από τον Εκτελούντα την Επεξεργασία σε χώρα εκτός ΕΟΧ.</p>
  <h4>5. Τεχνικά και οργανωτικά μέτρα</h4>
  <p><strong>5.1.</strong> Ο Εκτελών την Επεξεργασία δηλώνει και εγγυάται ότι λαμβάνει τα απαραίτητα τεχνικά και οργανωτικά μέτρα προστασίας δεδομένων τα οποία ανταποκρίνονται στις ελάχιστες απαιτήσεις τις οποίες προβλέπει ο ΓΚΠΔ 679/2016.</p>
  <h4>6. Διόρθωση και διαγραφή δεδομένων</h4>
  <p><strong>6.1.</strong> Ο Εκτελών την Επεξεργασία δύναται να διορθώνει, να διαγράφει ή να αποκλείει δεδομένα προσωπικού χαρακτήρα που τυγχάνουν επεξεργασίας για λογαριασμό του Υπευθύνου Επεξεργασίας, μόνο βάσει της συμβατικής συμφωνίας ή των οδηγιών του Υπευθύνου Επεξεργασίας.</p>
  <h4>7. Υπεργολαβία</h4>
  <p><strong>7.1.</strong> Τυχόν Υπεργολάβοι μπορούν να διορίζονται μόνο κατόπιν προηγούμενης ειδικής γραπτής άδειας του Υπευθύνου Επεξεργασίας.</p>
  <p><strong>7.</strong><strong>2</strong><strong>.</strong> Κάθε διαβίβαση στον Υπεργολάβο των δεδομένων προσωπικού χαρακτήρα τα οποία ο Εκτελών την Επεξεργασία επεξεργάζεται για λογαριασμό του Υπευθύνου Επεξεργασίας, επιτρέπεται μόνο αφού ο Εκτελών την Επεξεργασία έχει παράσχει στον Υπεύθυνο Επεξεργασίας τα κατάλληλα έγγραφα/στοιχεία σχετικά με την εκπλήρωση των υποχρεώσεων του Υπεργολάβου.</p>
  <p><strong>7.</strong><strong>3</strong><strong>.</strong> Απαγορεύεται σε κάθε περίπτωση ο ορισμός Υπεργολάβων οι οποίοι δεν εδρεύουν και δεν λειτουργούν αποκλειστικά εντός του ΕΟΧ.</p>
  <p><strong>7.</strong><strong>4</strong><strong>.</strong> Σε περίπτωση που ο Υπεργολάβος δεν εκπληρώσει τις υποχρεώσεις προστασίας δεδομένων προσωπικού χαρακτήρα, ο Εκτελών την Επεξεργασία θα ευθύνεται έναντι του Υπευθύνου Επεξεργασίας για την έλλειψη αυτή.</p>
  <h4>8. Δικαιώματα και Υποχρεώσεις του Υπευθύνου Επεξεργασίας</h4>
  <p><strong>8.1.</strong> Ο Υπεύθυνος Επεξεργασίας έχει το δικαίωμα να <strong>ελέγχει</strong> σε κατάλληλο βαθμό, είτε προσωπικά είτε μέσω τρίτων, την τήρηση των διατάξεων περί προστασίας δεδομένων προσωπικού χαρακτήρα και των συμβατικών συμφωνιών και υποχρεώσεων από τον Εκτελούντα την Επεξεργασία. Ειδικότερα, δικαιούται να ελέγχει τη λήψη πληροφοριών, την πρόσβαση στα αποθηκευμένα δεδομένα προσωπικού χαρακτήρα και τα προγράμματα επεξεργασίας δεδομένων προσωπικού χαρακτήρα, καθώς και να διενεργεί επιτόπιες επιθεωρήσεις. Ο Εκτελών την Επεξεργασία οφείλει να επιτρέπει σε όλα τα άτομα τα οποία είναι επιφορτισμένα με τη διενέργεια ελέγχων, να έχουν πρόσβαση και να επιθεωρούν όπως απαιτείται. Ο Εκτελών την Επεξεργασία υποχρεούται να παράσχει τις απαραίτητες πληροφορίες, να αποδείξει τις διαδικασίες και να παράσχει τα απαραίτητα έγγραφα για τη διενέργεια των επιθεωρήσεων.</p>
  <p><strong>8.</strong><strong>2</strong><strong>.</strong> Ο Υπεύθυνος Επεξεργασίας είναι υπεύθυνος για την παροχή των πληροφοριών στα Υποκείμενα των Δεδομένων αναφορικά με την επεξεργασία των δεδομένων προσωπικού χαρακτήρα, καθώς και για τη διασφάλιση των δικαιωμάτων των Υποκειμένων των Δεδομένων, στη βάση της ισχύουσας νομοθεσίας και του Κανονισμού 679/2016 (άρθρα 12-22 του ΓΚΠΔ).</p>
  <h4>9. Υποχρεώσεις κοινοποίησης</h4>
  <p><strong>9.1.</strong> Ο Εκτελών την Επεξεργασία <strong>ενημερώνει</strong> αμέσως τον Υπεύθυνο Επεξεργασίας για τυχόν <strong>παραβιάσεις των δεδομένων</strong> προσωπικού χαρακτήρα ή παραβίαση των όρων της παρούσας σύμβασης από τον Εκτελούντα την Επεξεργασία ή από τα άτομα που απασχολεί. Αντίστοιχα θα πρέπει επίσης να αναφέρονται τυχόν δικαιολογημένες υπόνοιες περιστατικών. Από τη στιγμή την οποία ο Εκτελών την Επεξεργασία θα εντοπίσει την επέλευση αντίστοιχου περιστατικού κατά τα ανωτέρω, θα υποχρεούται να ειδοποιήσει τον Υπεύθυνο Επεξεργασίας με κάθε πρόσφορο μέσο εντός 24 ωρών. Η κοινοποίηση αυτή θα πρέπει να γίνεται με βάση το υπόδειγμα που προσαρτάται στην παρούσα σύμβαση ως <strong>Παράρτημα 4</strong> και οφείλει να περιέχει τουλάχιστον τις ακόλουθες πληροφορίες:</p>
  <p>α. Περιγραφή του είδους της παραβίασης της προστασίας των δεδομένων προσωπικού χαρακτήρα, συμπεριλαμβανομένων, εάν είναι δυνατόν, των κατηγοριών και του κατά προσέγγιση αριθμού προσβεβλημένων προσώπων, καθώς και των αντίστοιχων κατηγοριών και του κατά προσέγγιση αριθμού των συνόλων προσωπικών δεδομένων.</p>
  <p>β. Το όνομα και τα στοιχεία επικοινωνίας του Υπευθύνου Προστασίας Δεδομένων ή άλλου προσώπου για περαιτέρω πληροφορίες.</p>
  <p>γ. Περιγραφή των πιθανών συνεπειών της παράβασης προστασίας προσωπικών δεδομένων.</p>
  <p>δ. Περιγραφή των μέτρων που ελήφθησαν ή προτάθηκαν από τον Εκτελούντα την Επεξεργασία για την αποκατάσταση της παραβίασης της προστασίας των προσωπικών δεδομένων και, κατά περίπτωση, μέτρων για τον μετριασμό των πιθανών δυσμενών επιπτώσεών τους.</p>
  <p>Σε περίπτωση που δεν είναι δυνατόν να παρασχεθούν οι πληροφορίες ταυτόχρονα, μπορούν να παρασχεθούν σταδιακά χωρίς αδικαιολόγητη καθυστέρηση.</p>
  <p><strong>9.</strong><strong>2</strong><strong>.</strong> Ο Εκτελών την Επεξεργασία θα συνδράμει τον Υπεύθυνο Επεξεργασίας με κάθε τρόπο για την εκπλήρωση των  υποχρεώσεων γνωστοποίησης στην εποπτική αρχή και το υποκείμενο των δεδομένων, σύμφωνα με τα άρθρα 33 και 34 του ΓΚΠΔ 679/2016 και στον βαθμό που απαιτείται.</p>
  <h4>10. Τερματισμός της επεξεργασίας προσωπικών δεδομένων</h4>
  <p><strong>10.1.</strong> Κατά τον τερματισμό της συμβατικής σχέσης ή ανά πάσα στιγμή κατόπιν αιτήματος του Υπευθύνου Επεξεργασίας, ο Εκτελών την Επεξεργασία οφείλει κατ’ επιλογήν του Υπευθύνου, είτε να καταστρέψει τα δεδομένα προσωπικού χαρακτήρα τα οποία επεξεργάστηκε στο πλαίσιο της Αρχικής Σύμβασης, είτε να επιστρέψει τα δεδομένα προσωπικού χαρακτήρα στον Υπεύθυνο Επεξεργασίας. Αντίστοιχα προβλέπεται και η καταστροφή όλων των αντιγράφων των εν λόγω δεδομένων από τον Εκτελούντα την Επεξεργασία.</p>
  <p>Η ως άνω υποχρέωση δεν καταλαμβάνει τα προσωπικά δεδομένα για τα οποία ο Εκτελών την επεξεργασία έχει καταστεί Υπεύθυνος Επεξεργασίας, δια της σύναψης της σύμβασης πώλησης απ’ευθείας με το Υποκείμενο.</p>
  <h4>11. Ευθύνη</h4>
  <p><strong>1</strong><strong>1</strong><strong>.1.</strong> Ο Υπεύθυνος Επεξεργασίας και ο Εκτελών την Επεξεργασία  που συμμετέχουν στην ίδια επεξεργασία είναι από κοινού υπεύθυνοι για την αποζημίωση της συνολικής ζημίας που προκλήθηκε στο Υποκείμενο, από οποιοδήποτε μη εξουσιοδοτημένο μέρος, παραβίαση ή μη νόμιμη επεξεργασία δεδομένων προσωπικού χαρακτήρα εντός του πεδίου εφαρμογής της σύμβασης και σύμφωνα με τις διατάξεις του ΓΚΠΔ.</p>
  <p><strong>1</strong><strong>1</strong><strong>.2.</strong> Ο Εκτελών την Επεξεργασία φέρει το βάρος να αποδείξει ότι οποιαδήποτε ζημία δεν οφείλεται σε περιστάσεις για τις οποίες αυτός είναι υπεύθυνος, εφόσον ο ίδιος έχει προβεί σε επεξεργασία των σχετικών δεδομένων προσωπικού χαρακτήρα βάσει της παρούσας συμφωνίας.</p>
  <p><strong>1</strong><strong>1</strong><strong>.3.</strong> Σε περίπτωση που ο Εκτελών την Επεξεργασία, οι υπάλληλοι και προστηθέντες του ή οι διορισμένοι από αυτόν Υπεργολάβοι παραβιάσουν τις νόμιμες ή τις συμβατικές τους υποχρεώσεις σε σχέση με την επεξεργασία των προσωπικών δεδομένων, τότε ο Εκτελών την Επεξεργασία θα ευθύνεται απεριόριστα έναντι του Υπευθύνου Επεξεργασίας για κάθε ζημία και θα τον απαλλάσσει από όλες τις αξιώσεις που έχουν εγερθεί έναντι του τελευταίου σε σχέση με την επεξεργασία δεδομένων (ενδεικτικά αξιώσεις υποκειμένων, διοικητικά πρόστιμα κ.ο.κ.).</p>
  <p><strong>1</strong><strong>1</strong><strong>.</strong><strong>4</strong><strong>.</strong> Ο Εκτελών την Επεξεργασία δεν φέρει ευθύνη κατά τα ανωτέρω, εάν η ζημία οφείλεται στην ορθή εφαρμογή της  νόμιμης εντολής που παρασχέθηκε από τον Υπεύθυνο Επεξεργασίας.</p>
  <h4>12. Δικαίωμα έκτακτης καταγγελίας</h4>
  <p><strong>1</strong><strong>2</strong><strong>.1.</strong> Ο Υπεύθυνος Επεξεργασίας μπορεί οποτεδήποτε να τερματίσει την Αρχική Σύμβαση και την παρούσα σύμβαση χωρίς προειδοποίηση («έκτακτη καταγγελία»), εάν υπάρχει σοβαρή παραβίαση του ΓΚΠΔ ή των διατάξεων της παρούσας σύμβασης εκ μέρους του Εκτελούντα την Επεξεργασία, εάν αυτός δεν μπορεί ή δεν εκτελεί τις νομικές οδηγίες του Υπευθύνου Επεξεργασίας ή αν αρνείται να αποδεχτεί τα εποπτικά δικαιώματα του Υπευθύνου Επεξεργασίας, κατά παράβαση της παρούσας σύμβασης.</p>
  <p><strong>1</strong><strong>2</strong><strong>.2.</strong> Θεωρείται σοβαρή παραβίαση, ιδίως, εάν ο Εκτελών την Επεξεργασία δεν έχει εκπληρώσει ή δεν εκπλήρωσε ουσιαστικά τις υποχρεώσεις που ορίζονται στην παρούσα συμφωνία, ιδίως ως προς την εφαρμογή των τεχνικών και οργανωτικών μέτρων.</p>
  <p><strong>1</strong><strong>2</strong><strong>.3.</strong> Για ασήμαντες παραβιάσεις, ο Υπεύθυνος Επεξεργασίας παρέχει στον Εκτελούντα την Επεξεργασία εύλογο χρονικό διάστημα για να αποκαταστήσει την παραβίαση. Σε περίπτωση όπου η κατάσταση δεν αποκατασταθεί σε εύλογο χρόνο, ο Υπεύθυνος Επεξεργασίας δικαιούται να ασκήσει το δικαίωμα έκτακτης καταγγελίας, όπως ορίζεται στην παρούσα.</p>
                        </b-card-text>
                        
                    </b-card>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
  },
}
</script>

<style lang="scss" >
    .container {
        max-width: 1000px;
    }
    h4 {
        margin: 25px 0;
    }
    td {
      border: 1px solid;
      padding: 10px;
    }
</style>
